import { css } from '@emotion/css';

export const footer = css`
  width: 100%;
  background: #340076 !important;
  color: white;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 10px;
`;

export const text = css`
  margin: 0;
  font-size: 12px;
`;

export const bsNavbar = css`
  background: #340076 !important;
`;
