import { css } from '@emotion/css';
import { PropsWithChildren } from 'react';

export default function Layout(props: PropsWithChildren) {
  return <div className={layout}>{props.children}</div>;
}

export const layout = css`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

Layout.Content = function Content(props: PropsWithChildren) {
  return <div className={layoutContent}>{props.children}</div>;
};

export const layoutContent = css`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-block: 10px;
`;
