import * as css from './Footer.styles';

function Footer() {
  return (
    <div className={css.footer}>
      <p className={css.text}>Copyright © 2023 Hellow All rights reserved.</p>
      <p className={css.text}>simon@hellow.id</p>
      <p className={css.text}>+62 877 90077698</p>
    </div>
  );
}

export default Footer;
