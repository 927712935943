import React from 'react';
import Image from 'next/image';
import Link from 'next/link';

import { Typography } from 'antd';
import * as css from './Navbar.styles';
import helloLogo from './assets/logo.png';
import helloBanner from './assets/banner.png';
import BoostrapContainer from 'react-bootstrap/Container';
import BootstrapNav from 'react-bootstrap/Nav';
import BootstrapNavbar from 'react-bootstrap/Navbar';
import BootstrapNavDropdown from 'react-bootstrap/NavDropdown';
// import bootstrap from "bootstrap";
// import BurgerMenu from "@/components/Sidebar";

// import { getLocalUser } from '@/helpers/localStorageWorker';

function Navbar() {
  return (
    // <nav></nav>
    <div className={css.bsNavbar}>
      <BootstrapNavbar className={`navbar-dark`} expand="lg">
        <BoostrapContainer className={`${css.bsNavbar} bg-dark`}>
          <BootstrapNavbar.Toggle
            aria-controls="hellow-navbar"
            data-bs-target="#hellow-navbar"
            data-target="#hellow-navbar"
          />
          <BootstrapNavbar.Collapse id="hellow-navbar">
            <BootstrapNav className="me-auto">
              <BootstrapNav.Item>
                <BootstrapNav.Link href="/">Home</BootstrapNav.Link>
              </BootstrapNav.Item>
              <BootstrapNav.Item>
                <BootstrapNav.Link href="/about">About</BootstrapNav.Link>
              </BootstrapNav.Item>
            </BootstrapNav>
          </BootstrapNavbar.Collapse>
          <Link href="/">
            <Image
              src={helloBanner}
              alt="logo"
              width={140}
              height={35}
              className="d-flex d-inline-block img-fluid"
            />
          </Link>
          <Link href="/"><Image src={helloLogo} alt="logo" width={70} height={70} /></Link>
        </BoostrapContainer>
      </BootstrapNavbar>
      {/* <nav className={`navbar navbar-collapse navbar-expand-lg navbar-dark`}>

        <div className="container bg-dark">
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-bs-target="#navbarSupportedContent" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto">
              <li className="nav-item"><Link href="/" className="nav-link">Home</Link></li>
              <li className="nav-item"><Link href="/" className="nav-link">About</Link></li>
              <li className="nav-item"><Link href="/" className="nav-link">Contact</Link></li>
            </ul>
          </div>
        </div>
      </nav> */}

      {/* <Link href = "/"><Image src={helloBanner} alt="logo" width={140} height={35} className="d-flex d-inline-block img-fluid"/></Link>

      <Image src={helloLogo} alt="logo" width={70} height={70}/> */}
    </div>
  );
}

export default Navbar;
