import { css } from '@emotion/css';

export const container = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 84px;
  left: 0px;
  top: 0px;

  background: #340076 !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 32px;
`;

export const bsNavbar = css`
  background: #340076 !important;
`;
