import { ConfigProvider } from 'antd';
import 'antd/dist/reset.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import type { AppProps } from 'next/app';
import '../styles/globals.css';

import Footer from '@/components/Footer';
import Layout from '@/components/Layout';
import Navbar from '@/components/Navbar';

export default function App({ Component, pageProps }: AppProps) {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#7600D3',
        },
      }}
    >
      <Layout>
        <Navbar />
        <Layout.Content>
          <Component {...pageProps} />
        </Layout.Content>
        <Footer />
      </Layout>
    </ConfigProvider>
  );
}
